import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Input,
  Row,
  Table,
} from "reactstrap";

import { createXlsx } from "./createXlsx";
import Pagination from "../../../components/pagination";
import {
  getByEventAndRoom,
  deleteSchedule,
} from "../../../services/scheduling";

import { formatDate, formatTime } from "../../../utils/functions";
import SearchFilters from "../../../components/SearchFilters";
import SectionComponent from "../../../components/SectionComponent";
import useDebounce from "../../../hooks/useDebounce";
// css
import "../../../styles/reports.css";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ReportByRoom() {
  const [appointments, setAppointments] = useState([]);
  const [search, setSearch] = useState("");
  const [eventId, setEventId] = useState("");

  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("participantName");

  // alerta
  const [alertConfirm, setAlertConfirm] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState("");
  // item selecionado
  const [selected, setSelected] = useState(null);

  const columnsFilter = [
    { name: "Participante", value: "participantName", type: "text" },
    { name: "Atividade", value: "atividade", type: "text" },
    { name: "Data", value: "dataHoraInicio", type: "date" },
  ];

  const debouncedSearch = useDebounce(search, 300);

  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function formatDateTime(data) {
    const date = new Date(data);
    const offset = -3 * 60; // Offset in minutes
    const localDate = new Date(date.getTime() + offset * 60 * 1000);
    const datePart = localDate.toISOString().slice(0, 10);
    const timePart = localDate.toISOString().slice(11, 16);

    return `${datePart} ${timePart}`;
  }
  async function deleteAppointmentsFunc() {
    await deleteSchedule(selected.id).then((res) => {
      if (res.message === "DELETED") {
        appointmentsByEvent();
        setAlertSuccess(true);
        setAlertConfirm(false);
      } else setAlertError("Erro ao tentar deletar atividade");
    });
    return;
  }

  const appointmentsByEvent = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const response = await getByEventAndRoom({
      eventId: urlParams.get("eventId"),
      room: urlParams.get("room"),
      offset: currentPage,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    setAppointments(response.data);
    setTotalItems(response.total);
    setEventId(urlParams.get("eventId"));
  };

  useEffect(() => {
    appointmentsByEvent();
  }, [debouncedSearch, currentPage, itemsPerPage]);

  return (
    <>
      {alertConfirm && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title="Deseja excluir essa atividade da sua agenda?"
          onCancel={() => setAlertConfirm(false)}
          onConfirm={() => deleteAppointmentsFunc()}
        />
      )}

      {alertSuccess && (
        <SweetAlert
          success
          title="Deletado!"
          onConfirm={() => setAlertSuccess(false)}
        >
          Atividade deletada com sucesso
        </SweetAlert>
      )}

      {alertError && (
        <SweetAlert error title="Erro" onConfirm={() => setAlertError("")}>
          {alertError}
        </SweetAlert>
      )}

      <div className="report-container">
        <Card className="card-container">
          <CardBody>
            <div className="card-title">
              <h5>Relatório de agendamentos</h5>
            </div>
            <div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  width: "50px",
                }}
              >
                <SectionComponent
                  sectionName="Filtros"
                  setIsOpen={setCollapseIsOpen}
                  isOpen={collapseIsOpen}
                />
              </Row>
              <Collapse isOpen={collapseIsOpen}>
                <SearchFilters
                  columns={columnsFilter}
                  activeFilter={activeFilter}
                  setActiveFilterHandle={setActiveFilterHandle}
                />
              </Collapse>
            </div>
            <div className="export-and-search-container">
              <Input
                className="search-input"
                type="text"
                placeholder="Pesquisar..."
                value={search}
                onChange={(e) => setSearch(e.target.value) > setCurrentPage(1)}
              />
              <Button
                color="success"
                onClick={() => {
                  const queryString = window.location.search;
                  const urlParams = new URLSearchParams(queryString);
                  const room = urlParams.get("room");
                  createXlsx({ eventId: eventId, countData: totalItems, room });
                }}
              >
                Exportar Relatório
              </Button>
            </div>
            <Table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>ID do Participante</th>
                  <th>Nome do Participante</th>
                  <th>Sala</th>
                  <th>Atividade</th>
                  <th>Data/Início</th>
                  <th>Data/Fim</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {appointments.length > 0 &&
                  appointments.map((item, index) => (
                    <tr key={index}>
                      <td>{item.participantId}</td>
                      <td>{item.participantName}</td>
                      <td>{item.sala}</td>
                      <td>{item.atividade}</td>
                      <td>{formatDateTime(item.dataHoraInicio)}</td>
                      <td>{formatDateTime(item.dataHoraFim)}</td>
                      <td style={{ textAlign: "center" }}>
                        <i
                          className="bx bx-trash icon-delete"
                          onClick={() =>
                            setSelected(item) > setAlertConfirm(true)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <div
          style={{ display: "flex", alignSelf: "flex-end", marginRight: "5%" }}
        >
          <Pagination
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
}
