import axios from "axios";
import { urlBase } from "./config";
// const urlBase = "https://agendamento-api.inteegratec.com.br";

async function getConfigScheduling(idEvent) {
  const config = {
    method: "get",
    url: `${urlBase}/agendamentoconfig/${idEvent}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getActivitiesRooms(idEvent) {
  const config = {
    method: "get",
    url: `${urlBase}/estoqueatividades/${idEvent}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getActivitiesByRoom(idEvent, room) {
  const config = {
    method: "get",
    url: `${urlBase}/estoqueatividades/${idEvent}/event/${room}/room`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getActivitiesByUser(idEvent, idPart) {
  const config = {
    method: "get",
    url: `${urlBase}/agendamento/${idEvent}/event/${idPart}/participant`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getByEventAndRoom({
  eventId,
  room,
  offset,
  limit,
  search,
  columnSearch,
}) {
  const config = {
    method: "get",
    url: `${urlBase}/agendamento/report/${eventId}/event/${room}/room?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function createSchedule(data) {
  const config = {
    method: "post",
    url: `${urlBase}/agendamento`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteSchedule(id) {
  const config = {
    method: "delete",
    url: `${urlBase}/agendamento/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log(config.url);

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export {
  getByEventAndRoom,
  getConfigScheduling,
  getActivitiesRooms,
  getActivitiesByRoom,
  createSchedule,
  getActivitiesByUser,
  deleteSchedule,
};
